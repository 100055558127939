/* You can add global styles to this file, and also import other style files */
:root {
  --color-primary: #027ac4;
  --color-secondary: #ffc032;
  --nav-primary-color: #332963;
  // Dashboard colors
  --dashboard-green: #049587;
  --dashboard-warn: #fbb519;
  /* ... */
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/cdk/overlay-prebuilt.css";

html,
body {
  height: 100vh;
  overflow-x: hidden;
}

body {
  position: relative;
}

.ng-select.custom.ng-select-single {
  .ng-select-container {
    height: 38px !important;
  }
}

.ball-clip-rotate {
  color: orange !important;
}

.ball-clip-rotate > div {
  background: orange !important;
}

form .required label:after {
  color: red;
  content: " \FF0A";
  position: absolute;
  font-size: 12px;
  right: -15px;
  top: 0px;
}

.no_test {
  color: red;
}

// ng-select globals

.ng-select.ng-select-focused {
  outline: none;
  border: none;
  .ng-select-container {
    // border-c: inherit !important;
    border-color: #cac7c7 !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.ng-select .ng-select-container {
  border-radius: 24px !important;
  border-color: #cac7c7;
  height: inherit !important;

  .ng-placeholder {
    color: #cac7c7;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .ng-value-container {
    .ng-value {
      background-color: #fff !important;
      // background-color: #f1f1ff !important;
      border-radius: 50px !important;
      overflow: hidden !important;
    }
  }

  .ng-value-icon.left {
    border-right: none !important;
    // background-color: #f1f1ff !important;
    background-color: #fff !important;
  }

  .ng-value {
    .ng-value-label {
      background-color: #fff !important;
      // background-color: #f1f1ff !important;
      font-family: Poppins !important;
      font-size: 14px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.filters.ng-select .ng-select-container {
  .ng-value-container {
    .ng-value {
      // background-color: #fff !important;
      background-color: #f1f1ff !important;
      border-radius: 50px !important;
      overflow: hidden !important;
      .ng-value-label {
        background-color: #f1f1ff !important;
      }
    }
  }

  .ng-value-icon.left {
    border-right: none !important;
    background-color: #f1f1ff !important;
    // background-color: #fff !important;
  }
}

.ng-dropdown-panel-items {
  .ng-option {
    font-family: Poppins !important;
    font-size: 14px;
  }

  &.scroll-host {
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1ff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: lightgrey;
      border-radius: 3px;
    }
  }
}

.ng-dropdown-panel {
  border: none !important;
  position: absolute;
  overflow-x: hidden !important;

  z-index: 999;
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 0px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.ng-select-disabled {
  .ng-arrow-wrapper {
    display: none;
  }
}

.ng-dropdown-panel.ng-select-top {
  margin-bottom: 0px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  overflow-y: hidden;
  box-shadow: 0 -20px 25px -5px rgba(0, 0, 0, 0.1),
    0 -8px 10px -6px rgba(0, 0, 0, 0.1);
}
.ng-select-wrapper {
  border-radius: 24px;
}
.ng-select-wrapper:has(.ng-dropdown-panel.ng-select-top) {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: 0 -20px 25px -5px rgba(0, 0, 0, 0.1),
    0 -8px 10px -6px rgba(0, 0, 0, 0.1);
}

.ng-select-wrapper:has(.ng-dropdown-panel.ng-select-bottom) {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}
.ng-option-selected {
  background-color: #f8f9fa !important;
  .ng-option-label {
    font-weight: inherit !important;
    color: inherit !important;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f1f1ff;
}

.ng-select.ng-select-disabled
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: #f5f7f8 !important;
  .ng-value-label {
    background-color: #f5f7f8 !important;
  }
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f5f7f8 !important;
}

.toast-top-right {
  top: 75px !important;
  right: 0px !important;
}

.app-input-field {
  border-radius: 24px !important;
  border-color: #cac7c7;
  outline: none !important;
  font-family: Poppins;
  font-size: inherit;
  width: 100%;
  &::placeholder {
    color: #cac7c7;
  }
  &:focus {
    border: 1px solid #cac7c7;
    outline: none;
    box-shadow: none;
  }
}

.app-textarea-field {
  border-radius: 24px !important;
  border-color: #cac7c7;
  outline: none !important;
  font-family: Poppins;
  font-size: inherit;
  width: 100%;
  min-height: 100px;
  resize: none;
  &::placeholder {
    color: #cac7c7;
  }
  &:focus {
    border: 1px solid #cac7c7;
    outline: none;
    box-shadow: none;
  }
}

.App__title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  color: #231433;
}

.App__outlined-button-primary {
  border-radius: 30px;
  border: 1px solid #332963;
  color: #332963;
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  gap: 4px;

  &:hover:not(:disabled) {
    background-color: #332963;
    color: #fff;
    svg {
      path {
        stroke: #fff;
      }
    }
  }
}

.App__outlined-button-danger {
  border-radius: 30px;
  border: 1px solid #c00c0c;
  color: #c00c0c;
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  gap: 4px;

  // &:hover {
  //   background-color: #332963;
  //   color: #fff;
  //   svg {
  //     path {
  //       stroke: #fff;
  //     }
  //   }
  // }
}

.App__filled-button-warn {
  border-radius: 30px;
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #332963;

  background-color: #fbb519;
  &:hover {
    background-color: #ffc700;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.form-checkbox {
  &:checked {
    background-color: #fbb519 !important;
  }
  &:focus {
    background-color: transparent;
    box-shadow: none !important;
    outline: none;
  }
  border-radius: 5px;
}
.form-checkbox-purple {
  &:checked {
    background-color: var(--nav-primary-color) !important;
  }
  &:focus {
    background-color: transparent;
    box-shadow: none !important;
    outline: none;
  }
  border-radius: 5px;
}
.mat-datepicker-toggle {
  .mat-mdc-icon-button {
    --mat-mdc-button-ripple-color: transparent;
    --mat-mdc-button-persistent-ripple-color: transparent;
  }
}

.mat-calendar-body-selected {
  --mat-datepicker-calendar-date-selected-state-background-color: #6963be1a;
  --mat-datepicker-calendar-date-selected-state-text-color: #332963;
}

.mat-datepicker-actions {
  justify-content: space-between !important;
  padding: 0 16px 16px 16px !important;
  // margin-top: -30px;
}

.app-custom-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(243 244 246);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(156 163 175);
    border-radius: 3px;
  }
}

.app-custom-scroll-md {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(243 244 246);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(156 163 175);
    border-radius: 3px;
  }
}

.app-custom-scroll-xl {
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(243 244 246);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(156 163 175);
    border-radius: 3px;
  }
}

input[type="radio"]:checked {
  background-color: #332963;
  &:focus {
    background-color: #332963;
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background-color: #332963;
    outline: none;
  }
}

input[type="radio"] {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.vat-id-select.ng-select {
  .ng-select-container {
    border-radius: 0px !important;
    border-width: 0px !important;
    margin-left: 3px;
    width: 80px !important;
  }
}

.App_top-shadow {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
}

.flag-icon-custom.flag-icon {
  background-size: cover;
}

// picker

.mat-calendar {
  --mat-datepicker-calendar-date-in-range-state-background-color: #fbb51980;
  --mat-datepicker-calendar-date-hover-state-background-color: #fbb51980;
}
